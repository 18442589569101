import { PublicKey } from '@solana/web3.js'
import { formatNumericValue, numberCompacter } from './numbers'
import { ethers } from 'ethers'
import dayjs from 'dayjs'

export function abbreviateAddress(address: PublicKey, size = 5) {
  const base58 = address.toBase58()
  return base58.slice(0, size) + '…' + base58.slice(-size)
}

export const formatYAxis = (value: number) => {
  return value === 0
    ? '0'
    : Math.abs(value) > 1
      ? numberCompacter.format(value)
      : formatNumericValue(value, 4)
}

export const tryParse = (val: string) => {
  try {
    const json = JSON.parse(val)
    return json
  } catch (e) {
    return val
  }
}

export const getShorterAddress = (
  addr: string,
  noOfDots = 7,
  charAtStart = 3,
  charAtEnd = 4,
) => {
  if (addr === '') {
    return addr
  }
  const address = addr && ethers.utils.getAddress(addr.toLowerCase())
  return (
    address.substring(0, charAtStart) +
    '.'.repeat(noOfDots) +
    address.substring(address.length - charAtEnd, address.length)
  )
}

export function relativeTimeFromNow(unixTimestamp: number): string {
  const now = dayjs()
  const then = dayjs.unix(unixTimestamp)
  const diffInSeconds = now.diff(then, 'second')

  const timeUnits = [
    { unit: 'year', seconds: 31536000 },
    { unit: 'month', seconds: 2592000 },
    { unit: 'day', seconds: 86400 },
    { unit: 'hour', seconds: 3600 },
    { unit: 'minute', seconds: 60 },
    { unit: 'second', seconds: 1 },
  ]

  for (const { unit, seconds } of timeUnits) {
    const count = Math.floor(diffInSeconds / seconds)
    if (count >= 1) {
      return `${count} ${unit}${count > 1 ? 's' : ''} ago`
    }
  }

  return 'just now'
}

//input--> unix timestamp
//output example--> 19d 20h 56m 47s
export function formatUnixTimestamp(unixTimestamp: number): string {
  const secondsInMinute = 60
  const secondsInHour = 60 * secondsInMinute
  const secondsInDay = 24 * secondsInHour

  const days = Math.floor(unixTimestamp / secondsInDay)
  unixTimestamp %= secondsInDay

  const hours = Math.floor(unixTimestamp / secondsInHour)
  unixTimestamp %= secondsInHour

  const minutes = Math.floor(unixTimestamp / secondsInMinute)
  const seconds = unixTimestamp % secondsInMinute

  return `${days}d ${hours}h ${minutes}m ${seconds}s`
}

//input-->unix timestamp
//output--> 11pm, Jul 26 2024
export function formatTimestamp(unixTimestamp: number): string {
  const date = new Date(unixTimestamp * 1000)

  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear()

  return `${month} ${day} ${year}`
}
